<template>
    <div class="">
        <div class="products-container">
            <div class="product" v-for=" (item,index) in productsList " :key="index">
                <div class="product">
                    <div class="card">
                        <div class="card-body text-center">
                            <img src="@/assets/images/ecart-ecommerce.png" class="img-cover-100" alt="">
                        </div>
                        <div class="card-footer">
                            <h5 v-text="item.name" class="text-truncate"></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                productsList: [{
                    id: 1,
                    name: 'Zapatos 1',
                }, {
                    id: 1,
                    name: 'Zapatos 2',
                }, {
                    id: 1,
                    name: 'Zapatos colores',
                }, {
                    id: 1,
                    name: 'Zapatos 5',
                }, {
                    id: 1,
                    name: 'Zapatos negros',
                }, {
                    id: 1,
                    name: 'Zapatos azules',
                }, {
                    id: 1,
                    name: 'Zapatos blancos',
                }, {
                    id: 1,
                    name: 'Zapatos 11',
                }, {
                    id: 1,
                    name: 'Zapatos 22',
                }]
            }
        }
    }
</script>

<style lang="scss">

.products-container{
    width: 100%;
    overflow-x: auto;
    display: flex;
    margin: 1rem 0;
    padding-left: 1rem;
    .product{
        width: 175px;
        margin-right: 1rem;
        .card{
            .card-footer{
                // text-overflow: ellipsis;
            }
        }
    }
}

</style>